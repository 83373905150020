.custom-dialog {
  height: 100px;
  max-height: 100%;
  align-items: center;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
} 

.posdialog-icon {
  font-size: 4rem !important;
}

.posdialog-icon.pos-icon {
  color: #708090; 
}

.posdialog-icon.cloud-icon {
  color: #708090; 
}

.dialog-title {
  text-align: center;
  font-size: 25px;
  font-style: normal;
  margin: 10px 0 1px 0; 
}

.dialog-description {
  text-align: center;
  font-style: arial;
  font-size: 15px; 
  color: #4d4a4a; 
}

.dialog-description1 {
    text-align: center;
    font-style: normal;
    font-size: 14px; 
  }
  

.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
}

.dots {
  display: inline-block;
  font-size: 4rem; 
  color: #514d4d; 
  margin: 0 5px; 
  opacity: 0; 
  animation: dot-blink 2s infinite;
}

@keyframes dot-blink {
  0%, 20% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  60%, 100% {
    opacity: 0;
  }
}

.dots:nth-child(1) {
  animation-delay: 0s;
}

.dots:nth-child(2) {
  animation-delay: 0.3s; 
}

.dots:nth-child(3) {
  animation-delay: 0.6s; 
}

.dots:nth-child(4) {
  animation-delay: 0.9s; 
}

.posdialog-icon.success-icon{
  color: green;
}

.posdialog-icon.warning-icon{
  color: red;
}