body {
    margin: 0px;
    font-family: sans-serif;
    font-size: 12px;
}
input {
    font-size: 12px;
}

table.grid-table {
    width: 100% !important;
}
table.grid-table th {
    width: fit-content;
    text-align: start;
}
table.grid-table td, table.grid-table th {
    padding: 0.2rem 0.2rem 0.2rem 0;
}

.forgot_link {
    color: #444;
    text-decoration: none;
}
.forgot_link:hover {
    text-decoration: underline;
}