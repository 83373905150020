.donacion_contenedor_imagen {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    box-shadow: 0px 0px 8px #00000044;
    transition: filter 300ms ease;
    cursor: pointer;
    background-color: #eeeeee;
}
.donacion_contenedor_imagen:hover {
    filter: brightness(0.9);
}
.donacion_image_file {
    position: absolute;
    width: 150px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
