.donacion_data_container {
    border-radius: 8px;
    padding: .5rem 1rem;
    box-shadow: 0 0 8px #00000044;
    height: 100%;
}

.donacion_logo_container {
    position: relative;
    width: 3rem;
    height: 2.5rem;
}
.donacion_logo_container img {
    max-width: 3rem;
    max-height: 2.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}