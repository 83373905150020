.filtro-texto-unselectbtn {
    border: none;
    color: black;
    background: none;
    transform: translate(-1.8rem, 50%);
    font-size: small;
    background-color: white;
    padding: 0;
    height: max-content;
    width: 20px;
    z-index: 1;
}
.filtro-texto-unselectbtn:hover {
    border: none;
    color: black;
    background: none;
    font-weight: bold;
    background-color: white;
}