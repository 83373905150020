input {
    padding: 8px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    transition: border 300ms ease;
    color: #404040;
}
input.gray {
    background-color: #F4F4F4;
}
input:focus {
    border: 1px solid #3490dc;
    outline: none;
}

input.error {
    background-color: rgba(255, 0, 0, 0.178);
}
input.disabled {
    color: #888888;
    background-color: #eeeeee;
}
label {
    color: red
}

.combobox_container {
    position: relative;
    width: fit-content;
}
.combobox_container::after{
    content: '⭣';
    position: absolute;
    right: 0.1rem;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 2;
    background-color: white;
}
.combobox_container input {
    cursor: pointer;
}

div.complete-width {
    display: flex;
}
div.complete-width input {
    flex-grow: 1;
}
.input_container {
    position: relative;
}