.t-stripped tr:nth-child(2n+1) {
    background-color: #C1C1C130;
}
.t-highlight tr:hover {
    background-color: #C1C1C140;
}

.pos-icon {
    color: inherit;
  }
  