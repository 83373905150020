.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  height: 40px;
}

.input {
  width: 25rem;
  align-items: center;
}
