.custom-dialog {
  width: 100% !important;
  height: 100% !important;
  max-width: 100%;
  max-height: 100%;
  justify-content: center !important;
  align-items: center !important;
  box-sizing: border-box; /* Incluye padding en el tamaño total */
  padding: 3.5%; /* Espacio uniforme alrededor */
}

/* El contenedor del diálogo */
.custom-dialog .MuiDialog-paper {
  margin: 0;
  width: 100%; /* Hacer que el papel ocupe todo el espacio del contenedor */
  height: 100%; /* Hacer que el papel ocupe todo el alto disponible */
  overflow: hidden;
}

/* El contenido del diálogo */
.custom-dialog .MuiDialogContent-root {
  padding: 40px;
  max-height: calc(
    100% - 80px
  ); /* Ajustar la altura disponible para el contenido */
  overflow-y: auto; /* Permitir desplazamiento vertical si el contenido excede */
}

/* Acciones del diálogo (botones de acción) */
.custom-dialog .MuiDialogActions-root {
  padding: 8px 24px;
}


/* Adaptaciones para pantallas pequeñas */
@media (max-width: 768px) {
  .custom-dialog .MuiDialog-paper {
    width: 100%; /* Ocupa todo el ancho en móviles */
    max-width: 90%; /* Asegura un límite en pantallas pequeñas */
    border-radius: 4px; /* Reducir bordes redondeados */
  }
  .custom-dialog .MuiDialogContent-root {
    padding: 16px; /* Reducir espaciado interno */
  }
}

/* Adaptaciones para pantallas con más altura (1920x1200, etc.) */
@media (min-aspect-ratio: 16/10) {
  .custom-dialog .MuiDialog-paper {
    max-height: 85vh; /* Reducir altura para pantallas más altas */
  }
}
