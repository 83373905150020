.MuiTab-root {
  display: inline-block;
  padding: 10px 25px !important;
  font-family: "Arial", sans-serif;
  font-weight: 400;
  font-size: 12px !important;
  text-transform: none;
  background-color: #efeff1 !important;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 0x;
  position: relative;
  white-space: nowrap; 
}
.MuiTab-root:hover {
  background-color: #ffffff !important;
  border-radius: 0px;
}
.MuiTab-root.Mui-selected {
  background-color: #ff4040 !important;
  color: #ffffff !important;
  border-radius: 0px;
}
.MuiTab-root.Mui-selected::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #ff4040;
  border-radius: 0 0 3px 3px;
}
.MuiTab-root:hover::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #ff4040;
  border-radius: 0 0 3px 3px;
}
.tabs-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  background-color: #a99e9e;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
.tab-content {
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}
.add-button-container {
  display: flex;
  justify-content: flex-end;
}
.filtro-texto {
  margin-right: 10px;
}
