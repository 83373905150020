button {
    color: white;
    background-color: #FF4040;
    border-radius: 4px;
    border: none;
    padding: 8px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 300ms ease;
}
button:hover {
    background-color: #e03535;
}
button.round {
    border-radius: 100px;
}

button.outlined {
    background-color: #00000000;
    border: 1px solid black;
    color: black;
}

button.outlined:hover {
    background-color: #E1E1E1;
    border: 1px solid black;
    color: black;
}

button.gray_button {
    background-color: #808080;
}
button.gray_button:hover {
    background-color: #707070;
}
button.disabled {
    color: #00000081;
    background-color: #0000001f;
    border: 1px solid #00000020;
    cursor: not-allowed;
    pointer-events: none;
}
.export-button {
    background-color: #008000;
    color: white;
    padding: 6px;
    cursor: pointer;
    border: 1px solid #00000020;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 5px;
    transition: background-color 300ms ease;
  }
  
  .export-button:hover {
    background-color: #158b15;
  }
  
  .export-button img {
    margin-left: 5px;
    width: 20px;
    height: 20px;
  }