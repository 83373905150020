.customselect {
        padding: 8px;
        border: 1px solid #c1c1c1;
        border-radius: 4px;
        transition: border 300ms ease;
        color: #404040;
        padding: 6px;
        min-width: 200px;
}
.customselect:focus {
    border: 1px solid #3490dc;
    outline: none;
}
.LogsTable .customselect {
    padding: 8px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    transition: border 300ms ease;
    color: #404040;
    padding: 6px;
    min-width: auto;
}
