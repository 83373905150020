.combobox_content_container {
    position: relative;
    overflow: visible;
}
.combobox_content {
    box-shadow: 0 0 10px #00000044;
    padding: 1rem 0;
    width: fit-content;
    max-height: 30vh;
    min-width: 150px;
    overflow-y: auto;
    position: absolute;
    z-index: 99;
    background-color: white;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 300ms ease ;    
    pointer-events: none !important;
}

.combobox_content.combobox_visible {
    opacity: 1;
    pointer-events: all !important;
}